@import "../../../../assets/scss/variable";

.eventsPrint{
     background: #3B3E58;
     padding: 15px 20px;
     border: 0;
     outline: none;
     width: 100%;
     display: flex; align-items: center;
     justify-content: center;
     border-radius: 60px;
     color: #fff; font-size: 20px;
}