@import "../../../../assets/scss/variable";

.CompetitionRegistration__search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.5rem;
    h3 {
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: $dashboard-text-color;
    }
    .CompetitionRegistration__search__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .CompetitionRegistration__delete__all {
            font-size: 24px;
            padding: 1rem;
            cursor: pointer;
            background-color: $dashboard-sidebar-color;
            border-radius: 50%;
            color: $label-danger-color;
            margin-right: 0.5rem;
        }
    }
}
.table-responsive {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 260px);
    overflow: auto;
    background-color: $dashboard-sidebar-color;
    border-radius: 24px;
    padding: 1rem;
    scrollbar-color: $dashboard-sidebar-color $dashboard-bg-color;
    scrollbar-width: thin;
    table {
        width: 100%;
        background-color: transparent;
        thead {
            background: #3b3e58;
            border-radius: 12rem;
            tr {
                th {
                    padding: 15px 30px;
                    text-align: center;
                    color: #fff;
                    font-size: 20px;
                    font-weight: 600;
                    &:first-child {
                        border-radius: 2rem 0 0 0;
                    }
                    &:last-child {
                        border-radius: 0 2rem 0 0;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 15px 30px;
                    color: $dashboard-text-color;
                    font-size: 14px;
                    font-weight: 400;
                    span {
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        i {
                            cursor: pointer;
                            padding: 0.2rem;
                            font-size: 25px;
                        }
                    }
                    &:nth-child(2) {
                        text-align: center;
                    }
                }
            }
        }
    }
}
.CompetitionRegistration__model__wrapper {
    .CompetitionRegistration__model__heading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            color: $dashboard-text-color;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        i {
            color: $dashboard-text-color;
            cursor: pointer;
            font-size: 30px;
        }
    }
    p,
    span {
        color: $dashboard-text-color;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        b {
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .CompetitionRegistration__model__btn {
        width: 100%;
        border-radius: 8px;
        background: #dc4c64;
        color: $dashboard-text-color;
        border: none;
        height: 50px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media screen and (max-width: 660px) {
    .CompetitionRegistration__search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        h3 {
            margin-bottom: 1rem;
        }
        .CompetitionRegistration__search__wrapper {
            .CompetitionRegistration__delete__all {
                font-size: 22px;
                padding: 0.5rem;
            }
        }
    }
    .input__wrapper {
        &.CompetitionRegistration__search__input {
            border-radius: 60px;
            margin: 0;
            width: 90%;
            height: 40px;
            input {
                &::placeholder {
                    color: $dashboard-text-color;
                }
            }
        }
    }
    .CompetitionRegistration__model__wrapper {
        .CompetitionRegistration__model__heading {
            h4 {
                font-size: 24px;
            }
            i {
                font-size: 24px;
            }
        }
        p,
        span {
            font-size: 15px;
            b {
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
        .CompetitionRegistration__model__btn {
            height: 50px;
            font-size: 18px;
        }
    }
}
