@import "../../assets/scss/variable";

.Badge__wrapper {
    width: auto;
    padding: 0.5rem 0.75rem;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 60px;
    color: #fff;
    cursor: pointer;
    &.success {
        background-color: $label-success-color;
    }
    &.warning {
        background-color: $label-warning-color;
    }
    &.primary {
        background-color: $label-primary-color;
    }
    &.danger {
        background-color: $label-danger-color;
    }
}
