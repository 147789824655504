.AttendanceMark__wrapper {
    border-radius: 24px;
    background: #2c2d3b;
    min-height: 83vh;
    max-height: 83vh;
    max-width: 240vh;
    overflow: auto;
    padding-top: 2rem;
    padding-left: 2rem;
    .AttendanceMark__qr__section {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        .AttendanceMark__qrVideo {
            object-fit: cover;
            border: 1px solid #ddd;
            width: 350px;
            height: 350px;
            border-radius: 50px;
        }
        .AttendanceMark__qr__section__data {
            table {
                margin-bottom: 1rem;
                tbody {
                    tr {
                        td {
                            color: #fff;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 421px) {
    .AttendanceMark__wrapper {
        .AttendanceMark__qr__section {
            flex-direction: column;
            .AttendanceMark__qrVideo {
                object-fit: cover;
                border: 1px solid #ddd;
                width: 100%;
                height: auto;
                aspect-ratio: 1/1;
                border-radius: 50px;
            }
            .AttendanceMark__qr__section__data {
                table {
                    margin-bottom: 1rem;
                    tbody {
                        tr {
                            td {
                                color: #fff;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}
