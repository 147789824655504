.login__wrapper {
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    background: url("../../../assets/images/authBackground.png") lightgray -0.589px -140px / 137.249% 162.685% no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        .login__card {
            background-color: transparent;
            border: none;
            width: 75%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .TitleImage {
                width: 200px;
                margin-bottom: 3rem;
            }
            .login__card__form {
                width: 520px;
                min-height: 411px;
                border-radius: 24px;
                background: #212231;
                box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h2 {
                    color: #fff;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .login__card__form__wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .inputWrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        width: 100%;
                        .input__error {
                            margin-left: 5%;
                        }
                    }
                    .login__button {
                        width: 90%;
                        height: 50px;
                        border-radius: 60px;
                        background: #003959;
                        border: none;
                        color: #fff;
                        font-family: Poppins;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 30px; /* 166.667% */
                    }
                }
                a {
                    color: #4d8aae;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px; /* 250% */
                }
            }
        }
    }
    .login__footer__text {
        color: #4d8aae;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

@media screen and (max-width: 520px) {
    .login__wrapper {
        .container {
            .login__card {
                width: 100%;
                .TitleImage {
                    width: 200px;
                    margin-bottom: 2rem;
                }
                .login__card__form {
                    width: 100%;
                    h2 {
                        font-size: 26px;
                    }
                    .login__button {
                        font-size: 16px;
                    }
                    a {
                        font-size: 12px;
                    }
                }
            }
        }
        .login__footer__text {
            font-size: 10px;
            width: 95%;
            left: 2.5%;
        }
    }
}

@media screen and (max-width: 1440px) {
    .login__wrapper {
        .container {
            .login__card {
                .TitleImage {
                    width: 300px;
                }
                .login__card__form {
                    width: 420px;
                    h2 {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}
