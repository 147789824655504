@import "../../assets/scss/variable.scss";

.DashboardLayout {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $dashboard-bg-color;
    .DashboardLayout__sidebar {
        height: 100vh;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .DashboardLayout__sidebar__wrapper {
            width: 90%;
            max-width: 320px;
            height: 95%;
            border-radius: 24px;
            background-color: $dashboard-sidebar-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            .Dashboard__sidebar__links {
                width: 100%;
                height: 80%;
                display: flex;
                align-items: center;
                flex-direction: column;
                .Dashboard__sidebar__title {
                    width: 100%;
                    object-fit: cover;
                }
                ul {
                    width: 100%;
                    margin-top: 2rem;
                    list-style-type: none;
                    padding: 0;
                    li {
                        margin-bottom: 1.8rem;
                        display: flex;
                        align-items: center;
                        i {
                            color: $dashboard-text-gray;
                            font-size: 20px;
                            padding-right: 6px;
                        }
                        a {
                            color: $dashboard-text-gray;
                            text-decoration: none;
                            font-weight: 400;
                        }
                        &.active {
                            i {
                                color: $dashboard-text-color;
                            }
                            a {
                                color: $dashboard-text-color;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .Dashboard__sidebar__profile {
                width: 100%;
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .Dashboard__sidebar__profile__admin {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    i {
                        padding: 0.7rem;
                        font-size: 20px;
                        color: $dashboard-text-gray;
                        background-color: #d9d9d9;
                        border-radius: 50%;
                        margin-right: 1rem;
                    }
                    h5 {
                        color: #fff;
                        font-weight: 700;
                        line-height: 1rem;
                        background-color: $dashboard-sidebar-color;
                        padding: 0;
                        margin: 0;
                        font-size: 18px;
                        small {
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }
                hr {
                    width: 100%;
                    height: 2px;
                    background-color: $dashboard-text-gray;
                }
                .Dashboard__sidebar__profile__logout {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a {
                        display: flex;
                        i {
                            font-size: 20px;
                            color: $dashboard-text-gray;
                        }
                        color: $dashboard-text-gray;
                        text-decoration: none;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .DashboardLayout__content {
        height: 100vh;
        max-height: 100vh;
        min-height: 100vh;
        overflow: hidden;
        .DashboardLayout__header {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .DashboardLayout__header__menu {
                display: none;
                color: $dashboard-text-color;
                font-size: 40px;
            }
            h1 {
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                font-family: BlackMango;
                color: $dashboard-text-color;
            }
            .DashboardLayout__header__timer {
                color: $dashboard-text-color;
                font-size: 24px;
                span {
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: $dashboard-text-color;
                }
            }
        }
        .content__wrapper {
            width: 100%;
            height: calc(100vh - 80px);
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 1.5rem;
        }
    }
}

@media screen and (max-width: 991px) {
    .DashboardLayout {
        .DashboardLayout__sidebar {
            justify-content: flex-start;
            position: fixed;
            top: 0;
            left: -100%;
            z-index: 9999;
            transition: all 0.5s ease-in-out;
            &.active {
                top: 0;
                left: 0;
                transition: all 0.5s ease-in-out;
            }
            .DashboardLayout__sidebar__wrapper {
                width: 320px;
                margin: 4% 0 0 1rem;
            }
        }
        .DashboardLayout__content {
            .DashboardLayout__header {
                .DashboardLayout__header__menu {
                    display: block;
                }
                h1 {
                    font-size: 30px;
                }
                .DashboardLayout__header__timer {
                    font-size: 20px;
                }
            }
        }
    }
}

p {
    color: #4e4e4f;
}

@media screen and (max-width: 767px) {
    .DashboardLayout {
        .DashboardLayout__content {
            .DashboardLayout__header {
                .DashboardLayout__header__menu {
                    font-size: 30px;
                }
                h1 {
                    font-size: 18px;
                }
                .DashboardLayout__header__timer {
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .DashboardLayout {
        .DashboardLayout__content {
            .DashboardLayout__header {
                h1 {
                    font-size: 25px;
                }
                .DashboardLayout__header__timer {
                    display: none;
                }
            }
        }
    }
}
