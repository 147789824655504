@import "../../../../assets/scss/variable";

.Dashboard__cards {
    .card {
        border-radius: 24px;
        background-color: $dashboard-sidebar-color;
        padding: 1rem;
        h5 {
            color: $dashboard-text-color;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        h1 {
            color: $dashboard-text-color;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        a {
            text-decoration: none;
            small {
                display: flex;
                align-items: center;
                color: $dashboard-text-gray;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
