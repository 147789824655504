    @import "../../../../assets/scss/variable";

.CompetitionStudents__navigation {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    a {
        text-decoration: none;
        display: inline-flex;
        border: none;
        padding: 0.5rem 1rem;
        align-items: center;
        gap: 5px;
        flex-shrink: 0;
        border-radius: 60px;
        background: #3b3e58;
        color: $dashboard-text-color;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    h2 {
        color: $dashboard-text-color;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 12px;
    }
}
.CompetitionStudents__school__data {
    width: 100%;
    border-radius: 16px;
    background-color: $dashboard-bg-color;
    padding: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 3rem;
    table {
        width: 55%;

        tr {
            td {
                font-size: 15px;
                color: $dashboard-text-color;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align-last: left;
            }
        }
    }
    div {
        color: $dashboard-text-color;
        h3 {
            font-weight: 600;
        }
    }
}
.CompetitionStudents__model {
    .Model__content {
        border-radius: 24px;
        border: 3px solid #3b3e58;
        background: #212231;
        width: 1020px;
        height: auto;
        header {
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 60px;
                border: 1px solid #fff;
                background-color: transparent;
                color: #fff;
                font-size: 18px;
                padding: 0.25rem 1rem;
                gap: 0.4rem;
            }
        }
        .CompetitionStudents__model__Qr__section {
            width: 90%;
            gap: 1rem;
            .table__content {
                flex-grow: 1;
                table {
                    background-color: transparent;
                    width: 100%;
                    tr {
                        td {
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: #fff;
                        }
                    }
                }
            }
            .qr__code {
                padding: 1rem;
                background-color: #fff;
            }
        }
        .CompetitionStudents__model__clipboard__section {
            .CompetitionStudents__model__clipboard__section__title {
                font-size: 15px;
                font-weight: 500;
                color: #fff;
            }
            .CompetitionStudents__model__clipboard {
                margin: 0px;
                background: #2c2d3b;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .CompetitionStudents__model {
        .Model__content {
    
            .CompetitionStudents__model__Qr__section {
                width: 90%;
                gap: 1rem;
                flex-direction: column;
                .table__content {
                    flex-grow: 1;
                    table {
                        background-color: transparent;
                        width: 100%;
                        tr {
                            td {
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                color: #fff;
                            }
                        }
                    }
                }
                .qr__code {
                    padding: 1rem;
                    background-color: #fff;
                }
            }
            .CompetitionStudents__model__clipboard__section {
                .CompetitionStudents__model__clipboard__section__title {
                    font-size: 15px;
                    font-weight: 500;
                    color: #fff;
                }
                .CompetitionStudents__model__clipboard {
                    margin: 0px;
                    background: #2c2d3b;
                }
            }
        }
    }
}

@media screen and (max-width: 460px) {
    .CompetitionStudents__school__data {
        width: auto;
        table {
            width: 100%;
            tr {
                td {
                    font-size: 15px;
                    color: $dashboard-text-color;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align-last: left;
                }
            }
        }
        div {
            color: $dashboard-text-color;
            h3 {
                font-weight: 600;
            }
        }
    }
}
